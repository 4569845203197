import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonExternal = makeShortcode("ButtonExternal");
const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "les-chambres-dhôtes-nancy-metz"
    }}>{`Les `}<strong parentName="h1">{`Chambres d’hôtes`}</strong>{` `}<strong parentName="h1">{`Nancy`}</strong>{` `}<strong parentName="h1">{`Metz`}</strong></h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/28e4fc40eb91a87992ead592da6b2869/1fe05/image.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAVCzok8mP//EABkQAAIDAQAAAAAAAAAAAAAAAAABAgMSEf/aAAgBAQABBQJ2T1bLQojno0ucrP/EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwGhf//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwGtf//EABwQAAIBBQEAAAAAAAAAAAAAAAABIQIRMTNBof/aAAgBAQAGPwLKLs4SkTSjX6f/xAAbEAEAAwEAAwAAAAAAAAAAAAABABFRMSFBkf/aAAgBAQABPyG+Goclx9nSIl+GWxR0bLKLPlwfE//aAAwDAQACAAMAAAAQy+//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8Qo2P/xAAXEQADAQAAAAAAAAAAAAAAAAAAASER/9oACAECAQE/EKVNH//EABwQAAICAgMAAAAAAAAAAAAAAAERACExQXGBof/aAAgBAQABPxBk7FCmri14U7qA5AJsElS78LCFj2JN9APsRgdtwKf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/28e4fc40eb91a87992ead592da6b2869/f994e/image.webp 163w", "/static/28e4fc40eb91a87992ead592da6b2869/bcd70/image.webp 325w", "/static/28e4fc40eb91a87992ead592da6b2869/5d8d7/image.webp 650w", "/static/28e4fc40eb91a87992ead592da6b2869/6c7d1/image.webp 960w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/28e4fc40eb91a87992ead592da6b2869/367e5/image.jpg 163w", "/static/28e4fc40eb91a87992ead592da6b2869/ab07c/image.jpg 325w", "/static/28e4fc40eb91a87992ead592da6b2869/ebabe/image.jpg 650w", "/static/28e4fc40eb91a87992ead592da6b2869/1fe05/image.jpg 960w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/28e4fc40eb91a87992ead592da6b2869/ebabe/image.jpg",
              "alt": "chambres d’hôtes nancy",
              "title": "chambres d’hôtes nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambres d’hôtes proche Nancy`}</strong></p>
    <h2 {...{
      "id": "une-nuit-romantique-dans-un-château-en-lorraine-"
    }}>{`Une `}<strong parentName="h2">{`nuit romantique`}</strong>{` dans un `}<strong parentName="h2">{`château en Lorraine`}</strong>{` ?`}</h2>
    <h3 {...{
      "id": "repos-et-calme-assuré-à-quelques-minutes-de-nancy"
    }}>{`Repos et calme assuré… À quelques minutes de `}<strong parentName="h3">{`Nancy`}</strong></h3>
    <p>{`Depuis 25 ans nous exerçons l’activité de `}<strong parentName="p">{`chambre d’hôtes`}</strong>{`. C’est un grand plaisir d’accueillir plus de 5 000 `}<strong parentName="p">{`voyageurs`}</strong>{` en nos lieux chaque année.`}</p>
    <p>{`Nous vous recevons afin que vous puissiez découvrir la `}<strong parentName="p">{`Lorraine`}</strong>{`, assurer un `}<strong parentName="p">{`moment de romantisme`}</strong>{`, vous échapperez de votre quotidien, ou autre, c’est avec enthousiasme que nous vous permettrons de réaliser vos plaisirs.`}</p>
    <p>{`Il est important pour le `}<strong parentName="p">{`Château de Morey`}</strong>{` de vous promettre confort et calme. Nos `}<strong parentName="p">{`chambres`}</strong>{` sont toutes parfaitement isolées de murs en pierres apparentes, elles assurent toutes un confort d’exception avec une literie de qualité. Toutes `}<strong parentName="p">{`nos chambres`}</strong>{` ont également la particularité d’être très spacieuses.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Réservez</ButtonExternal>
    <h2 {...{
      "id": "pour-vos-nuits-intimes-en-lorraine"
    }}>{`Pour vos nuits intimes en `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e5f290bd73f3916fcf58131988aa1728/a4af9/tourelle2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABd2IxndIjg//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhESIf/aAAgBAQABBQLqGZ5pD0bo2j//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPwG4Y//EABsQAAEEAwAAAAAAAAAAAAAAAAABEBEhEjFh/9oACAEBAAY/AppekvOS2bP/xAAbEAADAQADAQAAAAAAAAAAAAAAAREhMUFh4f/aAAgBAQABPyHCr6vBrb6OGWLJHBFzZhqfM//aAAwDAQACAAMAAAAQZw//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQMBAT8QEmW3/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EEHY/8QAHhABAAICAgMBAAAAAAAAAAAAAQARIUExUWFxobH/2gAIAQEAAT8QSiAtifqNRCjPPbfcuC78m4SwBgOT3mMgMg0AcfYwDun/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e5f290bd73f3916fcf58131988aa1728/f994e/tourelle2.webp 163w", "/static/e5f290bd73f3916fcf58131988aa1728/bcd70/tourelle2.webp 325w", "/static/e5f290bd73f3916fcf58131988aa1728/5d8d7/tourelle2.webp 650w", "/static/e5f290bd73f3916fcf58131988aa1728/c4443/tourelle2.webp 975w", "/static/e5f290bd73f3916fcf58131988aa1728/5cc32/tourelle2.webp 1300w", "/static/e5f290bd73f3916fcf58131988aa1728/1dd8b/tourelle2.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e5f290bd73f3916fcf58131988aa1728/367e5/tourelle2.jpg 163w", "/static/e5f290bd73f3916fcf58131988aa1728/ab07c/tourelle2.jpg 325w", "/static/e5f290bd73f3916fcf58131988aa1728/ebabe/tourelle2.jpg 650w", "/static/e5f290bd73f3916fcf58131988aa1728/cdb69/tourelle2.jpg 975w", "/static/e5f290bd73f3916fcf58131988aa1728/2616f/tourelle2.jpg 1300w", "/static/e5f290bd73f3916fcf58131988aa1728/a4af9/tourelle2.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e5f290bd73f3916fcf58131988aa1728/ebabe/tourelle2.jpg",
              "alt": "chambres d’hôtes nancy",
              "title": "chambres d’hôtes nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`chambres d’hôtes près de Nancy`}</strong></p>
    <h3 {...{
      "id": "cette-chambre-nuptiale-au-coeur-de-la-meurthe-et-moselle-vous-promet-un-séjour-romantique"
    }}>{`Cette chambre nuptiale au coeur de la `}<strong parentName="h3">{`Meurthe et Moselle`}</strong>{` vous promet un `}<strong parentName="h3">{`séjour romantique`}</strong></h3>
    <h4 {...{
      "id": "la-tourelle-pour-les-tourtereaux-en-chambre-dhôtes"
    }}>{`La Tourelle pour les tourtereaux en `}<strong parentName="h4">{`chambre d’hôtes`}</strong></h4>
    <p>{`Cette `}<strong parentName="p">{`chambre`}</strong>{` située dans une des tours du `}<strong parentName="p">{`Château`}</strong>{`. Le charme de ses pierres apparentes vous transporte dans une `}<strong parentName="p">{`ambiance médiévale`}</strong>{`.
Passez votre nuit dans un `}<strong parentName="p">{`lit baldaquin`}</strong>{` de 180m, confort et calme sont assurés.`}</p>
    <p>{`Profitez d’une vue sur notre grande forêt et sur l’allée cavalière du Château.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Réservez</ButtonExternal>
    <h2 {...{
      "id": "la-préférée-de-la-châtelaine-lorraine"
    }}>{`La préférée de la Châtelaine `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5642ba84ac9673b8d7e3612c6b9d07da/a4af9/chambres-dhotes-airbnb-nancy.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAf/aAAwDAQACEAMQAAABn0teJWSB3//EABgQAAMBAQAAAAAAAAAAAAAAAAECEgAR/9oACAEBAAEFAgtJC8POkTix07//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEZ/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEh/9oACAECAQE/AbGP/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQMREhUf/aAAgBAQAGPwLPBwtlss//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRYf/aAAgBAQABPyHaBY4ljDdF+TZhGFHosfVt1H7T/9oADAMBAAIAAwAAABDD/wD/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAEDAQE/EEPCH//EABYRAQEBAAAAAAAAAAAAAAAAAAARMf/aAAgBAgEBPxCGqf/EAB0QAQEAAgEFAAAAAAAAAAAAAAERACFRMUGBsdH/2gAIAQEAAT8QODNdIUQ2YBFUiMJ7w8b1bLnU+h5T7kYBpV7WTN2jOM//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5642ba84ac9673b8d7e3612c6b9d07da/f994e/chambres-dhotes-airbnb-nancy.webp 163w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/bcd70/chambres-dhotes-airbnb-nancy.webp 325w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/5d8d7/chambres-dhotes-airbnb-nancy.webp 650w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/c4443/chambres-dhotes-airbnb-nancy.webp 975w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/5cc32/chambres-dhotes-airbnb-nancy.webp 1300w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/1dd8b/chambres-dhotes-airbnb-nancy.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5642ba84ac9673b8d7e3612c6b9d07da/367e5/chambres-dhotes-airbnb-nancy.jpg 163w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/ab07c/chambres-dhotes-airbnb-nancy.jpg 325w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/ebabe/chambres-dhotes-airbnb-nancy.jpg 650w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/cdb69/chambres-dhotes-airbnb-nancy.jpg 975w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/2616f/chambres-dhotes-airbnb-nancy.jpg 1300w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/a4af9/chambres-dhotes-airbnb-nancy.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/5642ba84ac9673b8d7e3612c6b9d07da/ebabe/chambres-dhotes-airbnb-nancy.jpg",
              "alt": "chambres d’hôtes metz",
              "title": "chambres d’hôtes metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`chambres d’hôtes proche Metz`}</strong></p>
    <h3 {...{
      "id": "une-nuit-près-de-nancy-dans-une-chambre-de-princesse"
    }}>{`Une nuit `}<strong parentName="h3">{`près de Nancy`}</strong>{` dans une chambre de princesse`}</h3>
    <p>{`Une `}<strong parentName="p">{`chambre spacieuse`}</strong>{` de 64m2 et son `}<strong parentName="p">{`lit baldaquin`}</strong>{` vous fera vivre une `}<strong parentName="p">{`nuit`}</strong>{` de roi et reine.
Apprécier une grande douche à l’italienne et un coin salon pour vous détendre.
Une vue suprême sur la vallée de la Natagne avec ses esquises couleurs `}<strong parentName="p">{`Lorraine`}</strong>{`.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Réservez</ButtonExternal>
    <h2 {...{
      "id": "un-moment-en-famille-dans-la-suite-familiale-pour-un-séjour-à-nancy-"
    }}>{`Un moment en famille dans la Suite Familiale pour un séjour à `}<strong parentName="h2">{`Nancy`}</strong>{` ?`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c3536ba51be167d9b8df18ef93761b14/a4af9/suite.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAV3JFEvcREP/xAAZEAADAQEBAAAAAAAAAAAAAAAAARECAxL/2gAIAQEAAQUCsOe7qpiRxfke4//EABcRAQADAAAAAAAAAAAAAAAAAAABETH/2gAIAQMBAT8BnFP/xAAVEQEBAAAAAAAAAAAAAAAAAAACEP/aAAgBAgEBPwEz/8QAGRAAAgMBAAAAAAAAAAAAAAAAACEBAhAR/9oACAEBAAY/Ajg7bKP/xAAbEAEBAQACAwAAAAAAAAAAAAABEQAhMUFRcf/aAAgBAQABPyF4Hu6Epwd6liIzvXTlH3vBL9ymBLv/2gAMAwEAAgADAAAAEEM//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EJNP/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQEA/9oACAECAQE/EBaajv/EABoQAQEBAAMBAAAAAAAAAAAAAAERACExQWH/2gAIAQEAAT8QQ2leJkNH1IAajpAHA1N8g84ihhG9rxqYqgTrf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c3536ba51be167d9b8df18ef93761b14/f994e/suite.webp 163w", "/static/c3536ba51be167d9b8df18ef93761b14/bcd70/suite.webp 325w", "/static/c3536ba51be167d9b8df18ef93761b14/5d8d7/suite.webp 650w", "/static/c3536ba51be167d9b8df18ef93761b14/c4443/suite.webp 975w", "/static/c3536ba51be167d9b8df18ef93761b14/5cc32/suite.webp 1300w", "/static/c3536ba51be167d9b8df18ef93761b14/1dd8b/suite.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c3536ba51be167d9b8df18ef93761b14/367e5/suite.jpg 163w", "/static/c3536ba51be167d9b8df18ef93761b14/ab07c/suite.jpg 325w", "/static/c3536ba51be167d9b8df18ef93761b14/ebabe/suite.jpg 650w", "/static/c3536ba51be167d9b8df18ef93761b14/cdb69/suite.jpg 975w", "/static/c3536ba51be167d9b8df18ef93761b14/2616f/suite.jpg 1300w", "/static/c3536ba51be167d9b8df18ef93761b14/a4af9/suite.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c3536ba51be167d9b8df18ef93761b14/ebabe/suite.jpg",
              "alt": "chambres d’hôtes nancy",
              "title": "chambres d’hôtes nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`chambres d’hôtes près de Nancy`}</strong></p>
    <h3 {...{
      "id": "appréciez-votre-moment-en-famille-en-lorraine-à-proximité-de-nancy-et-metz"
    }}>{`Appréciez votre moment en `}<strong parentName="h3">{`famille`}</strong>{` en `}<strong parentName="h3">{`Lorraine`}</strong>{` à proximité de `}<strong parentName="h3">{`Nancy`}</strong>{` et `}<strong parentName="h3">{`Metz`}</strong></h3>
    <p>{`Le `}<strong parentName="p">{`Château`}</strong>{` vous accueille en couple comme en `}<strong parentName="p">{`famille`}</strong>{`, en mettant à disposition une majestueuse `}<strong parentName="p">{`chambre`}</strong>{` pour votre `}<strong parentName="p">{`couple`}</strong>{` accompagnée d’une `}<strong parentName="p">{`chambre`}</strong>{` annexe pour `}<strong parentName="p">{`vos enfants`}</strong>{`.`}</p>
    <p>{`Vous profiterez d’une salle de bain spacieuse et d’une vue imprenable sur `}<strong parentName="p">{`l’église romane du XII siècle`}</strong>{` et la `}<strong parentName="p">{`grande vallée Lorraine`}</strong>{`.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Réservez</ButtonExternal>
    <h2 {...{
      "id": "chambre-émile-friant"
    }}>{`Chambre Émile Friant`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1cf0c9321a39b0681be39d8ea400489d/a4af9/printaniere.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAdMTWs75kcT/xAAcEAABAwUAAAAAAAAAAAAAAAABAAISAwQRISP/2gAIAQEAAQUCbpSMcxVN5BuBxX//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BrX//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAQIR/9oACAECAQE/Aawx/8QAGhAAAQUBAAAAAAAAAAAAAAAAAAEREiExQf/aAAgBAQAGPwIStGI8EajT/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBUaH/2gAIAQEAAT8hdFEQPYtoCq7lFl9mtmCsFl5MvU//2gAMAwEAAgADAAAAEF8//8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAIf/aAAgBAwEBPxBnFzTZv//EABgRAAIDAAAAAAAAAAAAAAAAAAABESFh/9oACAECAQE/EKGpFs//xAAaEAEBAQEAAwAAAAAAAAAAAAABEQAhQVGR/9oACAEBAAE/EFouJGKoGp18y8gRecZlNMieHJ4g+oBPWV603//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1cf0c9321a39b0681be39d8ea400489d/f994e/printaniere.webp 163w", "/static/1cf0c9321a39b0681be39d8ea400489d/bcd70/printaniere.webp 325w", "/static/1cf0c9321a39b0681be39d8ea400489d/5d8d7/printaniere.webp 650w", "/static/1cf0c9321a39b0681be39d8ea400489d/c4443/printaniere.webp 975w", "/static/1cf0c9321a39b0681be39d8ea400489d/5cc32/printaniere.webp 1300w", "/static/1cf0c9321a39b0681be39d8ea400489d/1dd8b/printaniere.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1cf0c9321a39b0681be39d8ea400489d/367e5/printaniere.jpg 163w", "/static/1cf0c9321a39b0681be39d8ea400489d/ab07c/printaniere.jpg 325w", "/static/1cf0c9321a39b0681be39d8ea400489d/ebabe/printaniere.jpg 650w", "/static/1cf0c9321a39b0681be39d8ea400489d/cdb69/printaniere.jpg 975w", "/static/1cf0c9321a39b0681be39d8ea400489d/2616f/printaniere.jpg 1300w", "/static/1cf0c9321a39b0681be39d8ea400489d/a4af9/printaniere.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1cf0c9321a39b0681be39d8ea400489d/ebabe/printaniere.jpg",
              "alt": "chambres d’hôtes",
              "title": "chambres d’hôtes",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambres d’hôtes`}</strong></p>
    <h3 {...{
      "id": "un-séjour-en-chambre-dhôtes-aux-couleurs-intemporelles"
    }}>{`Un séjour en `}<strong parentName="h3">{`chambre d’hôtes`}</strong>{` aux couleurs intemporelles.`}</h3>
    <p>{`Reposez-vous dans cette `}<strong parentName="p">{`chambre prestigieuse`}</strong>{` avec sa décoration bourgeoise.
Vous apprécierez sa vue panoramique sur le `}<strong parentName="p">{`parc arboré du Château`}</strong>{` et la `}<strong parentName="p">{`vallée de Meurthe et Moselle`}</strong>{`.
Une grande salle de bain vous permettront de vous relaxer lors de votre `}<strong parentName="p">{`voyage`}</strong>{` entre `}<strong parentName="p">{`Nancy et Metz`}</strong>{`.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Réservez</ButtonExternal>
    <h2 {...{
      "id": "se-détendre-dans-la-chambre-stanislas"
    }}>{`Se détendre dans la `}<strong parentName="h2">{`chambre Stanislas`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6a87ca6c1ae4eb723ab482e7d9b55771/a4af9/colvert.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB79YoOsnrf//EABkQAAMBAQEAAAAAAAAAAAAAAAECEQATEv/aAAgBAQABBQKws4U+rurDdC7wjf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAMBAQEAAAAAAAAAAAAAAAABEQIxIf/aAAgBAQAGPwKEhc8L0VSPNNH/xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhQVExYf/aAAgBAQABPyG2NcGPJgTYPxBjjR3uNVF8nkM4T//aAAwDAQACAAMAAAAQ2M//xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhgf/aAAgBAwEBPxB25S5f/8QAGBEAAgMAAAAAAAAAAAAAAAAAADEBEWH/2gAIAQIBAT8QhMrT/8QAGxABAQADAAMAAAAAAAAAAAAAAREAITFRYZH/2gAIAQEAAT8QdS8Qu9swkjyafcNJ3HjHhuKFWPdyLwoTEPFx0s+Yf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6a87ca6c1ae4eb723ab482e7d9b55771/f994e/colvert.webp 163w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/bcd70/colvert.webp 325w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/5d8d7/colvert.webp 650w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/c4443/colvert.webp 975w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/5cc32/colvert.webp 1300w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/1dd8b/colvert.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6a87ca6c1ae4eb723ab482e7d9b55771/367e5/colvert.jpg 163w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/ab07c/colvert.jpg 325w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/ebabe/colvert.jpg 650w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/cdb69/colvert.jpg 975w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/2616f/colvert.jpg 1300w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/a4af9/colvert.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6a87ca6c1ae4eb723ab482e7d9b55771/ebabe/colvert.jpg",
              "alt": "chambres d’hôtes",
              "title": "chambres d’hôtes",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambres d’hôtes`}</strong></p>
    <h3 {...{
      "id": "le-symbole-de-la-lorraine-vous-transportera-dans-un-moment-authentique"
    }}>{`Le symbole de la `}<strong parentName="h3">{`Lorraine`}</strong>{` vous transportera dans un moment authentique`}</h3>
    <p>{`Offrez-vous une nuit dans une `}<strong parentName="p">{`chambre`}</strong>{` de 43m2 avec son `}<strong parentName="p">{`grand lit`}</strong>{` de 200x200.
Vous y retrouverez une baignoire pour vous permettre de vous prélasser.
Cette `}<strong parentName="p">{`chambre`}</strong>{` vous fera également profiter de la vue sur la vallée et sur le parc du `}<strong parentName="p">{`Château`}</strong>{`.
Appréciez le charme de cette `}<strong parentName="p">{`chambre`}</strong>{` qui reflète `}<strong parentName="p">{`l’authenticité de la Lorraine`}</strong>{`.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Réservez</ButtonExternal>
    <h2 {...{
      "id": "déjeuner-en-toute-tranquillité"
    }}>{`Déjeuner en toute tranquillité`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ba3ef274c3ef583c5369d5411d433412/a4af9/pdj.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABtykZ1Q54f//EABoQAQADAAMAAAAAAAAAAAAAAAIAASEDEjL/2gAIAQEAAQUCDNxvL2EdIvV8u//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ASf/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQETJh4f/aAAgBAQAGPwJ6HR2cUf/EABoQAQEBAQEBAQAAAAAAAAAAAAERACExQVH/2gAIAQEAAT8huh/DpLXfNaomFPVVt3E+l+56q7//2gAMAwEAAgADAAAAENTv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EAWt/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARQf/aAAgBAgEBPxBPLC//xAAeEAEBAAEDBQAAAAAAAAAAAAABEQAhQWExUXGhwf/aAAgBAQABPxA8oppxkEhEYa03n3EGGhsMWi+mpre2RcDMTXU399MNaHyTjP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ba3ef274c3ef583c5369d5411d433412/f994e/pdj.webp 163w", "/static/ba3ef274c3ef583c5369d5411d433412/bcd70/pdj.webp 325w", "/static/ba3ef274c3ef583c5369d5411d433412/5d8d7/pdj.webp 650w", "/static/ba3ef274c3ef583c5369d5411d433412/c4443/pdj.webp 975w", "/static/ba3ef274c3ef583c5369d5411d433412/5cc32/pdj.webp 1300w", "/static/ba3ef274c3ef583c5369d5411d433412/1dd8b/pdj.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ba3ef274c3ef583c5369d5411d433412/367e5/pdj.jpg 163w", "/static/ba3ef274c3ef583c5369d5411d433412/ab07c/pdj.jpg 325w", "/static/ba3ef274c3ef583c5369d5411d433412/ebabe/pdj.jpg 650w", "/static/ba3ef274c3ef583c5369d5411d433412/cdb69/pdj.jpg 975w", "/static/ba3ef274c3ef583c5369d5411d433412/2616f/pdj.jpg 1300w", "/static/ba3ef274c3ef583c5369d5411d433412/a4af9/pdj.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ba3ef274c3ef583c5369d5411d433412/ebabe/pdj.jpg",
              "alt": "chambres d’hôtes petit déjeuner",
              "title": "chambres d’hôtes petit déjeuner",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambres d’hôtes Petit Déjeuner`}</strong></p>
    <h3 {...{
      "id": "un-moment-dintimité-au-petit-matin-lorrain-assuré"
    }}>{`Un moment d’intimité au `}<strong parentName="h3">{`petit matin Lorrain`}</strong>{` assuré.`}</h3>
    <p>{`Nous pouvons également vous servir `}<strong parentName="p">{`un repas en table d’hôtes`}</strong>{` si besoin le soir.`}</p>
    <p>{`Voici la formule petit déjeuner:`}</p>
    <ul>
      <li parentName="ul">{`Boissons chaudes ☕`}</li>
      <li parentName="ul">{`Viennoiseries 🍞`}</li>
      <li parentName="ul">{`Jus d’orange bio 🍊`}</li>
      <li parentName="ul">{`Yaourt et fruit 🍶🍐🍏`}</li>
    </ul>
    <p>{`Vous apprécieriez ce `}<strong parentName="p">{`petit déjeuner offert`}</strong>{` qui est composés de `}<strong parentName="p">{`produits locaux et Lorrain`}</strong>{`.`}</p>
    <h2 {...{
      "id": "les-petits-détails-sont-souvent-appréciés"
    }}>{`Les petits détails sont souvent appréciés`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2UIGCCT/xAAZEAADAAMAAAAAAAAAAAAAAAAAAREQITH/2gAIAQEAAQUCWhM7iEP/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFX/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABgQAAIDAAAAAAAAAAAAAAAAAAABESBR/9oACAEBAAY/AnI9r//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExQXGR/9oACAEBAAE/IXdWq6GxLp5TYIex65f0/9oADAMBAAIAAwAAABCEL//EABcRAQADAAAAAAAAAAAAAAAAAAABQWH/2gAIAQMBAT8QpOH/xAAYEQACAwAAAAAAAAAAAAAAAAAAEQEhQf/aAAgBAgEBPxBWPEH/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUGB4fD/2gAIAQEAAT8Qojs8EMc2HqDPswXLVKbsS1rnHcfwQ3XjSf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/f994e/chateau_de_morey_mariage.webp 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/bcd70/chateau_de_morey_mariage.webp 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/797b9/chateau_de_morey_mariage.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/367e5/chateau_de_morey_mariage.jpg 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/ab07c/chateau_de_morey_mariage.jpg 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
              "alt": "chambres d’hôtes entre Nancy et Metz",
              "title": "chambres d’hôtes entre Nancy et Metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambres d’hôtes entre Nancy et Metz`}</strong></p>
    <h3 {...{
      "id": "vous-ne-manquerez-de-rien-lors-de-votre-séjour-entre-nancy-et-metz"
    }}>{`Vous ne manquerez de rien lors de votre séjour entre `}<strong parentName="h3">{`Nancy et Metz`}</strong></h3>
    <p>{`On sait que cela compte, les équipements de nos `}<strong parentName="p">{`chambres`}</strong>{` sont là pour perfectionner votre `}<strong parentName="p">{`voyage`}</strong>{` en `}<strong parentName="p">{`Meurthe et Moselle`}</strong>{`, `}<strong parentName="p">{`Lorraine`}</strong>{` ou `}<strong parentName="p">{`Nancy`}</strong>{`.
Retrouvez dans toutes nos `}<strong parentName="p">{`chambres`}</strong>{` :`}</p>
    <ul>
      <li parentName="ul">{`Accès wifi 🌐`}</li>
      <li parentName="ul">{`Télévision HD`}</li>
      <li parentName="ul">{`Sèche cheveux 💇‍♀`}</li>
      <li parentName="ul">{`Gel douche & shampoing 🚿`}</li>
      <li parentName="ul">{`Serviettes de bain & peignoirs 💆‍♀`}</li>
      <li parentName="ul">{`Documentation sur la Lorraine 📜`}</li>
      <li parentName="ul">{`Coin salon 🛋`}</li>
    </ul>
    <p>{`Émerveillez votre moment près de `}<strong parentName="p">{`Nancy et Metz`}</strong>{` avec de multiples activités dans notre établissement, comme SPA, massage, piscine, balade à cheval…`}</p>
    <ButtonInternal to="/chambres-dhotes/airbnb-nancy-metz" mdxType="ButtonInternal">En savoir plus</ButtonInternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      